import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_babel-plugin-react-compiler@19.0.0-beta-e99343_e384c66c0a8d9a5d137733e3c334ef05/node_modules/next/dist/client/components/render-from-template-context.js");
